import { useCallback, useEffect, useRef, useState } from 'react';
import Matter, { Engine } from 'matter-js';
import './GraphiteRenderer.css';
import { BLACK, GRAY } from '../Constants';

/**
 * Edge within an adjacency list graph.
 * Points to a single index with a weight (defaults to 1).
 */
interface Edge {
  weight: number,
  nodeId: string,
  id: string,
}

/**
 * Represents information about a node to render
 * in the Matter world and root div.
 */
interface NodeToRender {
  nodeId: string,
  value: string,
  edges: Edge[],
}

/**
 * Represents information about an edge to render in the
 * root div. Does not contain weight information.
 */
interface EdgeToRender {
  /**
   * The node ID to start from.
   */
  from: string,
  /**
   * The node ID to end at.
   */
  to: string,
  /**
   * Weight of the edge to render.
   */
  weight: number,

  /**
   * ID of the original edge within the graph.
   */
  edgeId: string,
}

/**
 * Represents information about a body to remove from the
 * Matter world and root div.
 */
interface BodyToRemove {
  bodyId: number,
  nodeId: string,
}

interface EdgeToRemove {
  constraintId: number,
  edgeId: string,
}

/**
 * Represents information about a node element to render
 * within the root div based on the Matter body.
 */
interface NodeRenderer {
  body: Matter.Body;
  element: HTMLDivElement;
  nodeId: string,

  /**
   * Method which sets the `top` and `left` properties
   * of the provided element.
   */
  render(): void;
}

/**
 * Represents information about an edge element to render
 * within the root div, based on the from and to Node bodies.
 */
interface EdgeRenderer {
  fromBody: Matter.Body;
  toBody: Matter.Body;
  lineElement: SVGLineElement,
  fromNodeId: string;
  toNodeId: string;
  weight: number,
  edgeId: string,
  render(): void;
}

/**
 * Object representing an adjacency list graph.
*/
export type Graph = {
  [value: string]: GraphNode
};

/**
 * Object representing information about a node on the graph.
 */
interface GraphNode {
  value: string,
  edges: Edge[],
}

/**
 * Object representing information about a node within the
 * Matter world.
 */
interface MemoryNode {
  bodyId: number,
  edges: MemoryEdge[],
}

/**
 * Object representing information about an edge within the
 * Matter world.
 */
interface MemoryEdge {
  constraintId: number,
  edgeId: string,
  nodeId: string,
}

export type WorldMemory = {
  [value: string]: MemoryNode,
}

interface Props {
  graph: Graph,
  // selectedNodeIndices: number[],
  // options: 
}

/**
 * Component which displays a matter.js view and
 * handles synchronization with the engine.
 * @returns
 */
export default function GraphiteRenderer({ graph }: Props) {
  const [initialized, setInitialized] = useState(false);

  const engineRef = useRef<Engine | null>(null);
  const renderRef = useRef<HTMLDivElement | null>(null);
  const edgeRenderRef = useRef<SVGSVGElement | null>(null);
  const animationRequestRef = useRef<number | null>(null);

  const memoryRef = useRef<WorldMemory>({});
  const nodeRenderersRef = useRef<NodeRenderer[]>([]);
  const edgeRenderersRef = useRef<EdgeRenderer[]>([]);

  // Scope (functional requirements) for renderer-foundation branch:
  // Initialize a full-screen renderer with matter.js
  // Graph state with an example graph in place
  // Ability to render new nodes and edges based on state change
  // Ability to remove nodes based on state change
  // Ability to drag nodes and have the entire graph move around
  // Custom renderer foundations

  // Future work:
  // Ability to select nodes
  // Ability to connect nodes with edges by dragging
  // Other UI shenanigans


  /**
   * Synchronize the React state with the Matter world,
   * and re-render the HTML5 div imperatively.
   */
  const syncAndAnimate = useCallback(() => {
    if (!engineRef.current || !renderRef.current || !edgeRenderRef.current) {
      return;
    }

    const nodesToRender: Set<NodeToRender> = new Set();
    const edgesToRender: Set<EdgeToRender> = new Set();
    const bodiesToRemove: Set<BodyToRemove> = new Set();
    const edgesToRemove: Set<EdgeToRemove> = new Set();

    const memory = memoryRef.current;
    const graphKeys = Object.keys(graph);
    const memoryKeys = Object.keys(memory);

    graphKeys.forEach((id) => {
      // Check if in memory
      // If not in memory, add to nodesToRender
      if (!memoryKeys.includes(id)) {
        nodesToRender.add({
          value: graph[id].value,
          edges: [...graph[id].edges],
          nodeId: id,
        });

        // Also add all edges from that graph node
        graph[id].edges.forEach((e) => {
          // If not in corresponding memory key
          edgesToRender.add({
            from: id,
            to: e.nodeId,
            weight: e.weight,
            edgeId: e.id,
          });
        })
      } else {
        // Otherwise, only add the necessary edges
        graph[id].edges.forEach((e) => {
          const memoryEdgeIndex = memory[id].edges.findIndex((memoryEdge) => memoryEdge.edgeId === e.id);
          // Check if in corresponding memory key
          if (memoryEdgeIndex === -1) {
            // Add to edgesToRender
            edgesToRender.add({
              from: id,
              to: e.nodeId,
              weight: e.weight,
              edgeId: e.id,
            });
          }
        });
      }
    });
    // console.log(edgesToRender);

    memoryKeys.forEach((gid) => {
      // Check if in graph
      // If not in graph, add to bodiesToRemove

      if (!graphKeys.includes(gid)) {
        bodiesToRemove.add({
          bodyId: memory[gid].bodyId,
          nodeId: gid,
        });
        // Also remove any edges associated with this body

        memory[gid].edges.forEach((edge) => {
          // Add to edgesToRemove
          edgesToRemove.add({
            constraintId: edge.constraintId,
            edgeId: edge.edgeId,
          });
        });

        // Also loop through entire memory to see which edges point @ gid
        memoryKeys.forEach((key) => {
          const edgeIndexToRemove = memory[key].edges.findIndex((edge) => edge.nodeId === gid);
          if (edgeIndexToRemove > -1) {
            // Remove the edge @ the given index
            edgesToRemove.add({
              constraintId: memory[key].edges[edgeIndexToRemove].constraintId,
              edgeId: memory[key].edges[edgeIndexToRemove].edgeId,
            })
          }
        })
      }
    })
    

    const world = engineRef.current.world;
    const render = renderRef.current;
    const edgeRender = edgeRenderRef.current;

    // For every node to render,
    // add to the world and update memory
    nodesToRender.forEach(({ value: v, edges, nodeId: id }) => {
      // Imperatively add nodes to the root div
      const newChild = document.createElement('div');
      newChild.setAttribute('id', id);
      newChild.setAttribute('class', 'Renderer-circle');

      const newChildParagraph = document.createElement('p');
      newChildParagraph.textContent = v;
      newChild.appendChild(newChildParagraph);

      renderRef.current?.appendChild(newChild);

      // Add to Matter world
      const nodeElement: NodeRenderer = {
        body: Matter.Bodies.circle(200, 200, 64, {
          isStatic: false,
          render: {
            fillStyle: GRAY,
          },
          frictionAir: 0.1,
        }),
        element: newChild,
        nodeId: id,
        render() {
          // TO-DO: node element rendering logic
          const { x, y } = this.body.position;
          // console.log(`Node ${id} rendering @ ${id}, ${id}`);

          this.element.style.top = `${y - 60}px`;
          this.element.style.left = `${x - 60}px`;
        }
      }

      Matter.World.add(world, [nodeElement.body]);
      nodeRenderersRef.current.push(nodeElement);

      // Add to memory
      memory[id] = {
        bodyId: nodeElement.body.id,
        edges: [],
      }
    });

    // For every body to remove,
    // remove it and update memory
    bodiesToRemove.forEach(({ bodyId: id, nodeId: gid }) => {
      // Imperatively remove nodes matching the given IDs
      const nodeToRemove = document.getElementById(gid);
      if (nodeToRemove) {
        render.removeChild(nodeToRemove);
      }

      const nodeElementToRemove = nodeRenderersRef.current.findIndex((nodeElement) => nodeElement.nodeId === gid);
      if (nodeElementToRemove > -1) {
        nodeRenderersRef.current.splice(nodeElementToRemove, 1);
      }

      const bodyToRemove = Matter.Composite.get(world, id, 'body');
      if (bodyToRemove) {
        Matter.World.remove(world, bodyToRemove);
      }

      // Update memory
      delete memory[id];
    });

    // Render edges as constraints
    edgesToRender.forEach(({ edgeId: id, from, to, weight }) => {
      const svgNS = 'http://www.w3.org/2000/svg';
      
      // Create edge element
      const newElement = document.createElementNS(svgNS, 'line');
      newElement.setAttribute('stroke-width', '1px');
      newElement.setAttribute('stroke', BLACK);
      newElement.setAttribute('id', id);

      edgeRender.appendChild(newElement);
      
      const bodyA = Matter.Composite.get(world, memory[from].bodyId, 'body') as Matter.Body;
      const bodyB = Matter.Composite.get(world, memory[to].bodyId, 'body') as Matter.Body;

      const constraint = Matter.Constraint.create({
        bodyA,
        bodyB,
        stiffness: 0.05,
      });

      Matter.World.add(world, constraint);

      const edgeElement: EdgeRenderer = {
        weight,
        fromBody: bodyA,
        toBody: bodyB,
        lineElement: newElement as SVGLineElement,
        fromNodeId: from,
        toNodeId: to,
        edgeId: id,
        render() {
          const { x: x1, y: y1 } = this.fromBody.position;
          const { x: x2, y: y2 } = this.toBody.position;
          this.lineElement.setAttribute('x1', `${x1}`);
          this.lineElement.setAttribute('y1', `${y1}`);
          this.lineElement.setAttribute('x2', `${x2}`);
          this.lineElement.setAttribute('y2', `${y2}`);
        }
      }
      
      edgeRenderersRef.current.push(edgeElement);

      memory[edgeElement.fromNodeId].edges.push({
        constraintId: constraint.id,
        edgeId: id,
        nodeId: to,
      });
    });

    edgesToRemove.forEach(({ constraintId, edgeId }) => {
      // Remove the edge ID from the DOM
      const element = document.getElementById(edgeId);
      if (element) {
        edgeRender.removeChild(element);
      }
      
      // Remove the constraint from the Matter world
      const edgeToRemove = Matter.Composite.get(world, constraintId, 'constraint');
      Matter.World.remove(world, edgeToRemove);

      // Remove from renderer array
      const edgeRendererToRemove = edgeRenderersRef.current.findIndex(({ edgeId: edgeRendererId }) => edgeRendererId === edgeId);

      
      if (edgeRendererToRemove > -1) {
        const renderer = edgeRenderersRef.current[edgeRendererToRemove];
        const edgeToRemove = memory[renderer.fromNodeId].edges.findIndex((e) => e.edgeId === edgeId);
        if (edgeToRemove > -1) {
          memory[renderer.fromNodeId].edges.splice(edgeToRemove);
        }
        
        edgeRenderersRef.current.splice(edgeRendererToRemove, 1);
      }

    });

    // TO-DO: actually render edges using HTML5
    (function rerender() {
      // console.log('Rerendering');
      nodeRenderersRef.current.forEach((nodeElement) => {
        nodeElement.render();
      });

      edgeRenderersRef.current.forEach((edgeElement) => {
        edgeElement.render();
      });

      Matter.Engine.update(engineRef.current);
      animationRequestRef.current = requestAnimationFrame(rerender);
    })();
  }, [graph]);

  useEffect(() => {
    // Will run when initialized or graph is updated
    if (initialized && engineRef.current) {
      // console.log(graph);
      syncAndAnimate();
    }

    return () => {
      if (animationRequestRef.current) {
        cancelAnimationFrame(animationRequestRef.current);
      }
    };

  }, [initialized, graph, syncAndAnimate]);

  // Initialize the engine and start rendering
  useEffect(() => {
    const { innerWidth, innerHeight } = window;

    engineRef.current = Matter.Engine.create();
    // renderRef.current = Matter.Render.create({
    //   element: document.getElementById('render') || undefined,
    //   engine: engineRef.current,
    //   options: {
    //     width: innerWidth,
    //     height: innerHeight,
    //     background: 'transparent',
    //     wireframes: false,
    //   },
    // });

    // Configure the world
    const world = engineRef.current?.world;
    engineRef.current.gravity.x = 0;
    engineRef.current.gravity.y = 0;

    // Add walls
    const wallOptions = {
      isStatic: true,
      render: {
        fillStyle: 'red',
      },
    };

    const leftWall = Matter.Bodies.rectangle(-49, 0, 100, innerHeight * 2, wallOptions);
    const rightWall = Matter.Bodies.rectangle(innerWidth + 49, 0, 100, innerHeight * 2, wallOptions);
    const topWall = Matter.Bodies.rectangle(0, -49, innerWidth * 2, 100, wallOptions);
    const bottomWall = Matter.Bodies.rectangle(0, innerHeight + 49, innerWidth * 2, 100, wallOptions);

    // Configure mouse
    // const mouse = Matter.Mouse.create(renderRef.current.canvas);
    const mouseConstraint = Matter.MouseConstraint.create(engineRef.current, {
      // @ts-ignore
      element: document.body,
    });
    if (world) {
      Matter.World.add(world, mouseConstraint);
      Matter.World.add(world, [leftWall, rightWall, bottomWall, topWall]);
    }

    Matter.Runner.run(engineRef.current);
    // Matter.Render.run(renderRef.current);

    setInitialized(true);

    // On unmount, stop rendering and clear the engine
    return () => {
      if (engineRef.current) {
        Matter.Engine.clear(engineRef.current);
      }

      // if (renderRef.current) {
      //   Matter.Render.stop(renderRef.current);
      // }
      // renderRef.current?.canvas.remove();
    }
  }, []);

  return (
    <div className="Renderer-container">
      <div id="render" ref={renderRef}>
      </div>
      <svg id="edgeRender" ref={edgeRenderRef}></svg>
    </div>
  );
}