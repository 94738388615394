import GraphiteRenderer, { Graph } from './components/GraphiteRenderer';
import './App.css';
import { useRef, useState } from 'react';
import generateId from './helpers/generateId';

function App() {
  const [graph, setGraph] = useState<Graph>({});
  const edgeIdsRef = useRef<Set<string>>(new Set());

  const countRef = useRef<number>(0);

  function handleAddNode() {
    countRef.current += 1;

    const id = generateId();
    const newValue = `Test ${countRef.current}`;
    setGraph({
      ...graph,
      [id]: {
        value: newValue,
        edges: [],
      },
    });
  }

  function handleAddRandomEdge() {
    const ids = Object.keys(graph);
    if (ids.length < 2) {
      return;
    }

    const randomIndex1 = Math.floor(Math.random() * ids.length);
    let randomIndex2 = Math.floor(Math.random() * ids.length);
    while (randomIndex1 === randomIndex2) {
      randomIndex2 = Math.floor(Math.random() * ids.length);
    }

    const newGraph = {
      ...graph
    };

    let edgeId1 = generateId();
    while (edgeIdsRef.current.has(edgeId1)) {
      edgeId1 = generateId();
    }

    let edgeId2 = generateId();
    while (edgeIdsRef.current.has(edgeId2)) {
      edgeId2 = generateId();
    }

    newGraph[ids[randomIndex1]].edges.push({
      weight: 1,
      nodeId: ids[randomIndex2],
      id: edgeId1,
    });
    newGraph[ids[randomIndex2]].edges.push({
      weight: 1,
      nodeId: ids[randomIndex1],
      id: edgeId2,
    });

    setGraph(newGraph);
  }

  function handleRemoveRandomNode() {
    // Get the IDs of the graph
    const ids = Object.keys(graph);
    if (ids.length <= 0) {
      return;
    }
    const randomIndex = Math.floor(Math.random() * ids.length);
    
    const newGraph = {
      ...graph
    };
    delete newGraph[ids[randomIndex]];
    const newGraphIds = Object.keys(newGraph);

    // We'll also need to update the edges of each vertex
    // so they don't point to any invalid nodes
    // Assume that the graph may be directed, and
    // loop through all nodes

    newGraphIds.forEach((id) => {
      const { edges } = newGraph[id];
      const edgeToRemove = edges.findIndex((edge) => edge.nodeId === ids[randomIndex]);
      if (edgeToRemove > -1) {
        const { id: edgeId } = edges[edgeToRemove];
        edgeIdsRef.current.delete(edgeId);
        edges.splice(edgeToRemove, 1);
      }
    });

    setGraph(newGraph);
  }

  return (
    <div className="App">
      <GraphiteRenderer graph={graph} />
      {/* <OptionsOverlay /> */}
      {/* <StatsOverlay /> */}
      {/* <a href="https://bchen.dev">Visit bchen.dev</a> */}

      {/* Test element with absolute positioning */}
      <div className="App-absolute">
        <button onClick={handleAddNode}>Add a node</button>
        <button onClick={handleRemoveRandomNode}>Remove a random node</button>
        <button onClick={handleAddRandomEdge}>Add a random edge</button>
      </div>
    </div>
  );
}

export default App;
